<template>
  <div
      :class="component === 'home' && auth ? 'auth-home-width' : component === 'home' && !auth ? 'no-auth-home-width' : component === 'animation-mobile' ||  component === 'video-mobile'? 'mobile-other-width' :  'other-home-width' "
  >
    <video ref="videoPlayer" crossorigin="anonymous"
           class="video-js vjs-fill" id="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
// import dynamicWatermark from 'videojs-dynamic-watermark';

export default {
  name: "VideoPlayer",
  props: {
    id: {
      type: String
    },
    username: {
      type: Object
    },
    component: {
      type: String
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      auth: false,
      player: null,
      test: '',
      currentTime: 1,
      lastTimeSeenSecond: 0,
      windowWidth: 1000
    }
  },
  mounted() {
    this.auth = this.$store.state.auth
    if (this.id) {
      window.axios.get("api/product/" + this.id + "/last-use").then((res) => {
        this.lastTimeSeenSecond = res.data
      })
    }
    this.windowWidth = window.innerWidth
    // videojs.registerPlugin('dynamicWatermark', dynamicWatermark)
    var myPlayer = this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
    })

    if (this.$route.path !== '/') {
      // var SELF = this
      // if (SELF.auth) {
      //   myPlayer.dynamicWatermark({
      //         elementId: 'watermark-content',
      //         watermarkText: SELF.$store.state.user.email,
      //         changeDuration: 7000,
      //         cssText: 'display: inline-block; color: grey; background-color: transparent; font-size: 1rem; z-index: 9999; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}'
      //       }
      //   )
      // }
    }

    var tracks = this.player.textTracks();

    for (var i = 0; i < tracks.length; i++) {
      var track = tracks[i];
      if (track.kind === "captions") {
        track.mode = "showing";
      }
    }
    const self = this
    myPlayer.on("durationchange", function () {
      let duration = this.duration() / 60
      self.$emit('setDuration', Math.floor(duration));
    });
    setInterval(function () {
      self.currentTime = Math.floor(myPlayer.currentTime())
    }, 3000);
    if (this.$route.path !== '/') {
      setInterval(function () {
        window.axios.post('api/videos/update-progress', {
          product_id: self.id,
          used: self.currentTime
        })
      }, 1000 * 60 * 2);
    }
  },
  updated() {
    if (this.$route.path !== '/') {
      window.axios.post('api/videos/update-progress', {
        product_id: this.id,
        used: this.currentTime
      })
    }
  },
  beforeDestroy() {
    if (this.$route.path !== '/' && this.id) {
      console.log('before destroy')
      window.axios.post('api/videos/update-progress', {
        product_id: this.id,
        used: this.currentTime
      })
    }
  },
  watch: {
    currentTime: function (value) {
      let currentTime = null
      if (value > this.lastTimeSeenSecond) {
        currentTime = value / 60
      } else {
        currentTime = this.lastTimeSeenSecond / 60
      }
      this.$emit('updateSlider', Math.floor(currentTime))
    }
  }
}
</script>
<style>
.auth-home-width {
  width: 43vw !important;
  height: 51vh !important
}

.no-auth-home-width {
  width: 46vw !important;;
  height: 52vh !important
}

.other-home-width {
  width: 48vw !important;
  height: 53vh !important
}

.mobile-other-width{
  width: 95vw !important;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  height: 53vh !important
}
.mobile-home-width{
  width: 90vw !important;
  height: 53vh !important
}

.watermark-content {

}

@keyframes example {
  0% {
    color: rgba(26, 25, 23, 0.3);
    top: 50%;
    right: 30px
  }
  1% {
    color: rgba(225, 227, 230, 0.4);
    top: 50%;
    right: 30px
  }
  4% {
    color: rgba(225, 227, 230, 0.001);
    bottom: 40%;
    left: 30px
  }
  5% {
    color: rgba(232, 16, 16, 0.3);
    bottom: 13px;
    right: 55%
  }
  6% {
    color: rgba(0, 0, 0, 0.001);
    bottom: 10px;
    right: 50%
  }
  10% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px;
    right: 80px
  }
  15% {
    color: rgba(19, 63, 176, 0.49);
    top: 98px;
    right: 5px
  }
  16% {
    color: rgba(149, 110, 27, 0.2);
    bottom: 98px;
    right: 98px
  }
  17% {
    color: rgba(149, 110, 27, 0.002);
    bottom: 98px;
    right: 98px
  }
  19% {
    color: rgba(253, 247, 247, 0.001);
    bottom: 98px;
    right: 80px
  }
  20% {
    color: rgba(253, 247, 247, 0.10);
    bottom: 98px;
    right: 80px
  }
  21% {
    color: rgba(253, 247, 247, 0.001);
    bottom: 98px;
    right: 80px
  }
  25% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px;
    right: 200px
  }
  30% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px;
    right: 200px
  }
  35% {
    color: rgba(253, 247, 247, 0.01);
    top: 100px;
    right: 250px
  }
  40% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  45% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  49% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  50% {
    color: rgba(253, 247, 247, 0.12);
    top: 5%;
    left: 150px;
  }
  51% {
    color: rgba(253, 247, 247, 0.001);
    top: 98px
  }
  55% {
    color: rgba(143, 215, 35, 0.11);
    top: 98px
  }
  56% {
    color: rgba(253, 253, 247, 0.3);
    top: 98px
  }
  60% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  65% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  70% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  75% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  80% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  85% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  89% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  90% {
    color: rgba(236, 22, 22, 0.2);
    bottom: 98px;
    left: 50px;
  }
  91% {
    color: rgba(236, 129, 22, 0.03);
    bottom: 50%;
    left: 5%;
  }
  92% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
  95% {
    color: rgba(216, 2, 2, 0.3);
    top: 98px
  }
  97% {
    color: rgba(253, 247, 247, 0.003);
    top: 98px
  }
  100% {
    color: rgba(253, 247, 247, 0.01);
    top: 98px
  }
}

video {
  border-radius: 1vw !important;
}

.video-js {
  border-radius: 1vw !important;
}

.vjs-control-bar {
  border-radius: 0 0 1.2vw 1.2vw !important;
}
</style>