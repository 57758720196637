<template>
  <div class="mt-2">
    <v-row class="mx-0 px-0 mb-0 pb-0 footer-mobile-container">
      <v-col @click="$router.push('/my-page')">
        <img src="@/assets/home.png" alt="home" height="32px" width="32px">
      </v-col>
      <v-col @click="$router.push('/books')">
        <img src="@/assets/book.png" alt="book" height="32px" width="32px">
      </v-col>
      <v-col @click="$router.push('/animations')">
        <img src="@/assets/animation.png" alt="animation" height="32px" width="32px">
      </v-col>
      <v-col @click="$router.push('/videos')">
        <img src="@/assets/video.png" alt="video" height="29px" width="34px">
      </v-col>
      <v-col @click="$router.push('/questions/new')">
        <img src="@/assets/question-2.png" alt="video" height="35px" width="31px">
      </v-col>
      <v-col @click="$router.push('/live-review-list')" class="pr-4">
        <img src="@/assets/live-review2.png" alt="video" height="32px" width="32px">
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MobileIconFooter",
  data: () => {
    return {
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>
.footer-mobile-container{
  width: 100%;
  position: fixed!important;
  bottom: 0!important;
  background-color: #f2f3f7!important;
  height: 60px;
  font-size: 8px;
  color: #374859;
}
</style>