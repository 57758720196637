<template>
  <v-app v-if="currentPath && !$store.state.loading">
    <div v-if="screenType === 'desktop'">
      <!--      <v-app-bar-->
      <!--          height="85px"-->
      <!--          v-if="mobile && $store.state.auth && currentPath !== '/questions/exam' && currentPath.indexOf('/exam/predefined/') === -1"-->
      <!--          style="z-index: 99999999"-->
      <!--          fixed-->
      <!--          color="#ffffff"-->
      <!--      >-->
      <!--        <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>-->
      <!--        <v-img-->
      <!--            src="@/assets/logo.svg"-->
      <!--        >-->
      <!--        </v-ogin/Register-->
      <!--        <v-spacer></v-spacer>-->

      <!--        <div v-if="$store.state.auth && mobile" class="text-center d-flex justify-center align-center">-->
      <!--          <v-img max-width="35px" max-height="35px" :src="baseURL + $store.state.user.data['image']"></v-img>-->
      <!--          <v-menu offset-y rounded>-->
      <!--            <template v-slot:activator="{ on, attrs }">-->
      <!--              <v-btn-->
      <!--                  height="60px"-->
      <!--                  text-->
      <!--                  v-bind="attrs"-->
      <!--                  v-on="on"-->
      <!--              >-->
      <!--                {{ $store.state.user.data['first_name'] }}-->
      <!--              </v-btn>-->
      <!--            </template>-->
      <!--            <v-list>-->
      <!--              <v-list-item to="/profile">-->
      <!--                <v-list-item-icon>-->
      <!--                  <v-icon>mdi-account</v-icon>-->
      <!--                </v-list-item-icon>-->
      <!--                <v-list-item-title>Personal Profile</v-list-item-title>-->
      <!--              </v-list-item>-->
      <!--              <v-list-item to="/change-password">-->
      <!--                <v-list-item-icon>-->
      <!--                  <v-icon>mdi-lock</v-icon>-->
      <!--                </v-list-item-icon>-->
      <!--                <v-list-item-title>Change Password</v-list-item-title>-->
      <!--              </v-list-item>-->
      <!--              <v-list-item to="/orders">-->
      <!--                <v-list-item-icon>-->
      <!--                  <v-icon>mdi-basket</v-icon>-->
      <!--                </v-list-item-icon>-->
      <!--                <v-list-item-title>Orders</v-list-item-title>-->
      <!--              </v-list-item>-->
      <!--              <v-list-item to="/reset">-->
      <!--                <v-list-item-icon>-->
      <!--                  <v-img src="@/assets/icons/Reset.svg"></v-img>-->
      <!--                </v-list-item-icon>-->
      <!--                <v-list-item-title style="color: #ff4949">Reset</v-list-item-title>-->
      <!--              </v-list-item>-->
      <!--              <v-list-item to="/logout">-->
      <!--                <v-list-item-icon>-->
      <!--                  <v-icon>mdi-logout</v-icon>-->
      <!--                </v-list-item-icon>-->
      <!--                <v-list-item-title>Log Out</v-list-item-title>-->
      <!--              </v-list-item>-->
      <!--            </v-list>-->
      <!--          </v-menu>-->
      <!--        </div>-->
      <!--      </v-app-bar>-->
      <div :style="{ marginTop: mobile && $store.state.auth ? '100px' : '0' }">
        <side-bar ref="sidebarComponent"
                  v-if="$store.state.auth && currentPath !== '/questions/exam' && currentPath.indexOf('/exam/predefined/') === -1 && currentPath !== '/questions/review'"
                  :mobile="mobile">
        </side-bar>
        <div :style="{ marginLeft: $store.state.auth && !mobile ? '111.3px' : '0'}">
          <nav-bar
              v-if="!(
                    currentPath === '/my-page' ||
                    currentPath === '/questions/prior' ||
                    currentPath === '/questions/performance' ||
                    currentPath === '/questions/notes' ||
                    currentPath === '/questions/search'||
                    currentPath === '/questions/help'||
                    currentPath === '/questions/exam' ||
                    currentPath === '/questions/review' ||
                    currentPath.indexOf('/exam/predefined/') !== -1 ||
                    currentPath.indexOf('/animation/') !== -1 ||
                    currentPath.indexOf('/video/') !== -1 ||
                    currentPath.indexOf('/book/') !== -1 ||
                    $route.path === '/books' ||
                    $route.path === '/questions/new' ||
                    $route.path === '/animations' ||
                    $route.path === '/videos')"
              :is-mobile="mobile"
              :isAuthenticated="$store.state.auth"
              :user="$store.state.user"
              :live-review="liveReview"
              :resources="resources"
              :abouts="abouts">
          </nav-bar>
          <short-nav-bar
              v-else-if="currentPath !== '/questions/exam' && currentPath !== '/questions/review' && currentPath.indexOf('/exam/predefined/') === -1"
              :is-mobile="mobile"
              :isAuthenticated="$store.state.auth"
              :user="$store.state.user"
          >
          </short-nav-bar>
          <div>
            <router-view/>
          </div>
          <Footer
              v-if="currentPath !== '/questions/exam' && currentPath !== '/questions/review' && currentPath.indexOf('/exam/predefined/') === -1 && currentPath !== '/login-register'"
              :live-review="liveReview"
              :resources="resources"
              :is-mobile="mobile"
              :abouts="abouts">
          </Footer>
        </div>
      </div>
    </div>
    <div v-else :id="currentPath == '/questions/exam' ? '' : 'over-flow-x-y-hidden'">
      <div>
        <mobile-auth-header v-if="(
          currentPath === '/login-register' ||
          currentPath === '/forgot-password'||
          currentPath === '/') && !$store.state.auth
        ">
        </mobile-auth-header>
        <mobile-default-header
            v-if="$store.state.auth && this.currentPath !== '/my-page'"
            :user="$store.state.user"
        >
        </mobile-default-header>
        <router-view/>
        <mobile-text-footer v-if="
          currentPath === '/login-register'||
          currentPath === '/forgot-password' ||
          currentPath === '/' ||
          currentPath === '/my-page'||
          currentPath === '/products'||
          currentPath === '/demo'
        ">
        </mobile-text-footer>
        <mobile-icon-footer v-if="
          currentPath === '/questions/new'||
          currentPath === '/questions/prior'||
          currentPath === '/questions/notes'||
          currentPath === '/questions/search'||
          currentPath === '/questions/performance'||
          currentPath === '/questions/help'||
          currentPath === '/books'||
          currentPath === '/videos' ||
          currentPath === '/animations'||
          currentPath === '/bookmarks-index'||
          currentPath.indexOf('/animation/') !== -1 ||
          currentPath.indexOf('/video/') !== -1 ||
          currentPath.indexOf('/book/') !== -1
        ">

        </mobile-icon-footer>
        <!--          <Footer-->
        <!--              v-if="currentPath !== '/questions/exam' && currentPath !== '/questions/review' && currentPath.indexOf('/exam/predefined/') === -1 && currentPath !== '/login-register'"-->
        <!--              :live-review="liveReview"-->
        <!--              :resources="resources"-->
        <!--              :is-mobile="mobile"-->
        <!--              :abouts="abouts">-->
        <!--          </Footer>-->
      </div>
    </div>
  </v-app>
</template>
<script>

import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import SideBar from "@/components/SideBar";
import ShortNavBar from "@/components/ShortNavBar";
import MobileAuthHeader from "@/components/mobile/header/MobileAuthHeader.vue";
import MobileTextFooter from "./components/mobile/footer/MobileTextFooter.vue";
import MobileDefaultHeader from "./components/mobile/header/MobileDefaultHeader.vue";
import MobileIconFooter from "./components/mobile/footer/MobileIconFooter.vue";

export default {
  data() {
    return {
      screenType: null,
      componentLoaded: false,
      windowHeight: screen.height,
      liveReview: null,
      resources: null,
      abouts: null,
      showNav: false,
      currentPath: null
    }
  },
  components: {
    MobileIconFooter,
    MobileDefaultHeader, MobileTextFooter, MobileAuthHeader, SideBar, Footer, NavBar, ShortNavBar
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : window.screen.width < 1000 ? 'tablet' : 'desktop'
    },
    getProfile() {
      window.axios.get('/api/profile').then((res) => {
        this.$store.state.user = res.data.data
        this.$store.state.auth = true
        this.$store.state.loading = false
      }).catch((err) => {
        this.$store.state.loading = false
        if (err.response.status === 401 && this.$route.path === '/my-page') {
          this.$router.push('/')
        } else if (err.response.status === 401 && this.$route.path !== '/login-register' && this.$route.path !== '/' && this.$route.path.indexOf("blog") < 0
            && this.$route.path.indexOf("live-review") < 0 && this.$route.path.indexOf("reset-password") < 0
        ) {
          this.$router.push('/login-register')
        }
      })
    },
    toggleDrawer() {
      this.$refs.sidebarComponent.toggleDrawer()
    }
  },
  updated() {
    this.currentPath = this.$route.path
  },
  mounted() {
    this.getScreenType()
    this.getProfile()
    this.currentPath = this.$route.path
    // window.axios.get('api/live-review').then((res) => {
    //   this.liveReview = res.data.data
    // });
    window.axios.get('api/resources-keys').then((res) => {
      this.resources = res.data.data
    });
    window.axios.get('api/abouts-keys').then((res) => {
      this.abouts = res.data.data
    });
  }
  ,
  created() {
    addEventListener('resize', () => {
      this.mobile = innerWidth <= 700
    })
  }
  ,
}
;

</script>

<style lang="scss">
@font-face {
  font-family: 'Blinker-ExtraBold';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

* {
  font-family: Blinker-SemiBold;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#page-products video{
  width: 100%;
  height: 100%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#over-flow-x-y-hidden {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
</style>
