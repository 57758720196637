import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/demo',
        name: 'Demo',
        component: () => import('../views/Demo.vue'),
    },
    {
        path: '/login-register',
        name: 'LoginRegister',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginRegister.vue'),
        meta: {
            requiresGuest: true
        },
        props: {
            NeedAuthPopup: false
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue'),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue'),
        props: {
            token: ''
        }
    },
    {
        path: '/book/:id',
        name: 'Book',
        component: () => import('../views/Book.vue'),
    },
    {
        path: '/exam/predefined/:url',
        name: 'SingleTemplateExam',
        component: () => import('../views/exam/SingleTemplateExam'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/books/',
        name: 'Books',
        component: () => import('../views/Books.vue'),
        props: {
            category: null,
            childCategory: null,
        }
    },
    {
        path: '/video/:id',
        name: 'Video',
        component: () => import('../views/Video.vue'),
    },
    {
        path: '/videos/',
        name: 'Videos',
        component: () => import('../views/Videos.vue'),
        props: {
            category: null,
            childCategory: null,
        }
    },
    {
        path: '/animation/:id',
        name: 'Animation',
        component: () => import('../views/Animation.vue'),
    },
    {
        path: '/animations/',
        name: 'Animations',
        component: () => import('../views/Animations.vue'),
        props: {
            category: null,
            childCategory: null,
        }
    },
    {
        path: '/plans/:id',
        name: 'Plan',
        component: () => import('../views/Plan.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/plans/',
        name: 'Plans',
        component: () => import('../views/Plans.vue')
    },
    {
        path: '/contact/',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/help/',
        name: 'Help',
        component: () => import('../views/Help.vue')
    },
    {
        path: '/notes-index/',
        name: 'NotesIndex',
        component: () => import('../views/NotesIndex'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/bookmarks-index/',
        name: 'BookmarksIndex',
        component: () => import('../views/BookmarksIndex'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/bookmarks/:type',
        name: 'Bookmarks',
        component: () => import('../views/Bookmarks.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/change-password',
        name: 'changePassword',
        component: () => import('../views/ChangePassword.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/new',
        name: 'Questions',
        component: () => import('../views/Questions.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/exam',
        name: 'Exam',
        component: () => import('../views/Question.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/related/:type/:id',
        name: 'relatedProducts',
        component: () => import('../views/Related.vue')
    },
    {
        path: '/questions/prior',
        name: 'PriorTest',
        component: () => import('../views/PriorTest.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/performance',
        name: 'Performance',
        component: () => import('../views/Performance.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/notes',
        name: 'Notes',
        component: () => import('../views/Notes'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/help',
        name: 'QuestionsHelp',
        component: () => import('../views/QuestionsHelp'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/finish',
        name: 'QuestionsFinished',
        component: () => import('../views/QuestionsFinished'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/review',
        name: 'ReviewTest',
        component: () => import('../components/exam/questions/ReviewTest'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/result',
        name: 'ResultTest',
        component: () => import('../components/exam/questions/ResultTest'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/questions/Search',
        name: 'Search',
        component: () => import('../views/QuestionsSearch'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/resources/:id',
        name: 'Resources',
        component: () => import('../views/Resources.vue')
    },
    {
        path: '/about/:id',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/medical-schools',
        name: 'MedicalSchools',
        component: () => import('../views/MedicalSchools.vue')
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import('../views/Products.vue')
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import('../views/Payment.vue'),
    },
    {
        path: '/order/:productId',
        name: 'Order',
        component: () => import('../views/Order.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('../views/Orders.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/my-page',
        name: 'MyPage',
        component: () => import('../views/MyPage.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('../views/Posts.vue')
    },
    {
        path: '/blog/:id',
        name: 'Blog',
        component: () => import('../views/Post.vue')
    },

    {
        path: '/bar-news',
        name: 'BarNews',
        component: () => import('../views/BarNews.vue')
    },
    {
        path: '/bar-news/:id',
        name: 'BarNewsPost',
        component: () => import('../views/BarNewsPost.vue')
    },

    {
        path: '/live-reviews/',
        name: 'LiveReviews',
        component: () => import('../views/LiveReviews')
    },
    {
        path: '/live-review-list',
        name: 'LiveReviewList',
        component: () => import('../views/LiveReviewList'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/classes',
        name: 'Classes',
        component: () => import('../views/Classes.vue'),
        meta: {
            requiresAuth: true
        }
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
