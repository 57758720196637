<template>
  <v-card color="transparent" elevation="0">
    <v-card-title>
      <v-row class="plan-title">
        <v-col>
          <span class="plan-title-span">{{ plan.title }}</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="plan-card-body">
      <v-row class="pt-1">
        <v-col cols="1"></v-col>
        <v-col cols="10" class="plan-card-body-text" v-html="plan.description">
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-9">
          <v-divider style="background-color:#65686c!important">
          </v-divider>
        </v-col>
        <v-col cols="6" v-if="plan.expire">
          <span
              style="font-size: 1.1vw!important;font-family: Blinker-SemiBold,serif!important;color: #2d3136!important">{{
              plan.expire
            }} / days</span>
        </v-col>
        <v-col cols="6" v-else>
          <span
              style="font-size: 1.1vw!important;font-family: Blinker-SemiBold,serif!important;color: #2d3136!important">∞</span>
        </v-col>
        <v-col cols="6">
          <span style="font-family: Blinker-Bold,serif!important;color:#26bf81;font-size: 1.2vw!important">{{
              plan.price
            }} $</span>
        </v-col>
      </v-row>
      <v-row class="plan-button-area">
        <v-col>
          <v-btn class="plan-button" :to="'/plans/' + plan.id" width="11vw" height="6vh" color="#39bf88">
            <span class="plan-button-text text-capitalize" v-if="userOwnsPlan">Renew</span>
            <span class="plan-button-text text-capitalize" v-else>Buy</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PlanCard",
  props: ['plan', 'userOwnsPlan'],
  data() {
    return {
      windowWidth: 1000
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.plan-card-body {
  min-height: 35vh !important;
  z-index: 1000 !important;
  position: absolute;
  margin-top: -3.6vh;
  padding: 0.8vw;
  border-radius: 2.5vw !important;
  background-color: #f8f8fa;
}

.plan-card-body-text {
  justify-content: left !important;
  text-align: left !important;
  font-size: 1vw;
  color: #65686c !important;
  font-family: Blinker-Regular;

}

.plan-card-body-text-semi-bold {
  font-size: 1vw;
  color: #65686c !important;
  font-family: Blinker-SemiBold;
}

.plan-title {
  border-radius: 1.8vw 1.8vw 1px 1px !important;
  background-image: linear-gradient(to right, #ffc6a6, #ff8640);
  padding: 0.6vw 0.7vw 1.6vw 0.7vw !important;
}

.plan-title-span {
  padding: 0.6vw !important;
  font-family: Blinker-Bold;
  font-size: 1.3vw !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.plan-button {
  width: 80%;
  border-radius: 5vw;
  box-shadow: none !important;
}

.plan-button-area {
  bottom: 0.8vw !important;
  margin-bottom: 1vh !important;;
  position: absolute;
  width: 100%;
}

.plan-button-text {
  font-size: 1vw !important;
  color: white !important;
}

@media only screen and (max-width: 450px) {
  .plan-expire-on {
    position: initial;
  }
}
</style>