<template>
  <div class="mt-2">
    <v-row class="mx-0 px-0 mb-0 pb-0">
      <v-col cols="12" class="footer-mobile-container pt-8 mx-0 px-0 mb-0 pb-0">
        The United States Medical Licensing Examination (USMLE@) is a joint program of the Federation of <br>
        the State Medical Boards (FSMB@ and National Board of Medical Examiners (NBME@) <br>
        Best Aid Reviews, LLC
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MobileTextFooter",
  data: () => {
    return {
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>
.footer-mobile-container{
  position: absolute!important;
  bottom: 0!important;
  background-color: #f2f3f7!important;
  height: 100px;
  font-size: 8px;
  color: #374859;
}
</style>