<template>
  <div>
    <v-row
        style="margin-top: 14.5px;"
    >
      <v-col v-if="!mobile" cols="2">
        <img
            @click="$router.push('/')"
            style="cursor: pointer; max-width: 200px; margin-left: 1vw;margin-top: -4vh!important"
            src="@/assets/logo.svg"
        >
      </v-col>
      <v-col
          cols="10"
      >
        <v-tabs
            show-arrows
            align-with-title
            active-class="activeTab"
            hide-slider
            optional
            height="68px"
        >
          <v-tab
              class="text-capitalize mx-0"
              text
              plain
              to="/">
            <span :class="$route.path === '/' ? 'tabs-content-active' : 'tabs-content' ">
               Home
            </span>
          </v-tab>
          <v-tab
              class="text-capitalize mx-0"
              text
              plain
              to="/products">
            <span :class="$route.path === '/products' ? 'tabs-content-active' : 'tabs-content' ">
            Products
          </span>
          </v-tab>
          <v-tab
              class="text-capitalize mx-0"
              text
              plain
              style="color: #a8a8a8"
              to="/live-reviews"
          >
            <span :class="$route.path === '/live-review' ? 'tabs-content-active' : 'tabs-content' ">
            Live Review
                        </span>
          </v-tab>
          <v-tab
              class="text-capitalize mx-0"
              text
              plain
              style="color: #a8a8a8"
              to="/medical-schools">
            <span :class="$route.path === '/medical-schools' ? 'tabs-content-active' : 'tabs-content' ">
            Medical Schools
                        </span>
          </v-tab>
          <v-tab
              class="text-capitalize px-0"
              text
              plain
              >
            <v-menu open-on-hover offset-y rounded="lg" style="z-index: 99999; width: 100px!important"
                    >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    style="color: #a8a8a8"
                    class="navBtn"
                >
                    <span
                        :class="$route.path.includes('/about') || $route.path.includes('/resources')  ? 'tabs-content-active' : 'tabs-content' ">
                            About us
                    </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="v-list-item--link">
                  <v-list-item-title
                      @click="$router.push('/about/1')"
                      text
                      style="color: #a8a8a8"
                      class="navBtn">{{
                      abouts != null ? abouts[0].title : ''
                    }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item--link">
                  <v-list-item-title
                      @click="$router.push('/about/2')"
                      text
                      style="color: #a8a8a8"
                      class="navBtn">{{
                      abouts != null ? abouts[1].title : ''
                    }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item--link">
                  <v-list-item-title
                      @click="$router.push('/bar-news')"
                      text
                      style="color: #a8a8a8"
                      class="navBtn">Bar News
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
          <v-tab
              class="text-capitalize mx-0"
              text
              plain
              style="color: #a8a8a8;margin-right: -0.8vw!important"
              to="/contact">
                        <span :class="$route.path === '/contact' ? 'tabs-content-active' : 'tabs-content' ">
            Contact Us
                        </span>
          </v-tab>
          <v-tab
              class="text-capitalize mx-0 mr-16 ml-4"
              text
              plain
              style="color: #a8a8a8"
              to="/blog">
                        <span :class="$route.path === '/blog' ? 'tabs-content-active' : 'tabs-content' ">
            Resources
                        </span>
          </v-tab>


          <v-btn
              class="ml-16 pa-3"
              fab
              to="/help/"
              style="color: white; margin-top: 2.1vh; box-shadow: none!important;right:24vw!important;position: absolute!important"
              color="#a8a8a8"
              width="0.9vw"
              height="0.9vw"
          >
            <v-icon white>
              mdi-help
            </v-icon>
          </v-btn>
          <v-btn
              class="pa-3"
              fab
              text
              to="/orders"
              style="color: white; margin-top: 2.1vh;right:21vw!important;position: absolute!important"
              width="1.1vw"
              height="1.1vw"
          >
            <v-icon color="#a8a8a8" style="width: 1.2vw!important">
              mdi-cart
            </v-icon>
          </v-btn>
          <v-dialog
              v-model="dialog"
              width="500"
              fullscreen
              transition="dialog-bottom-transition"
              style="z-index: 99999!important;"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mx-0 pa-3"
                  fab
                  dark
                  style="color: white; margin-top: 2.1vh;right:18vw!important;position: absolute!important;box-shadow: none!important;"
                  color="#a8a8a8"
                  width="0.9vw"
                  height="0.9vw"
                  v-bind="attrs"
                  v-on="on">
                <v-icon white>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-container>
                <v-card-title>
                  <v-text-field
                      v-model="searchInput"
                      outlined
                      color="#f1803f"
                      label="Search"
                      placeholder="Type Something"
                      v-debounce:1s.cancelonempty="search" type="text"
                  ></v-text-field>
                  <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                  >
                    <v-icon color="red">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row v-if="isLoading">
                    <v-col sm="12">
                      <v-progress-circular
                          :size="25"
                          :width="5"
                          color="#ffbe66"
                          indeterminate
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="this.searchResult != null">
                    <v-col sm="12" md="4" v-for="product in searchResult.data" :key="product.id">
                      <v-card
                          class="mx-auto"
                          min-width="100%"
                          color="#f2f3f7"
                          elevation="6"
                      >
                        <img
                            @click="$router.push('/' + product.type + '/' + product.id)"
                            style="cursor: pointer"
                            width="100%"
                            :src="baseURL + product.image">

                        <v-card-title>
                          {{ product.title }}
                        </v-card-title>
                        <v-card-subtitle>
                          {{ product.type }}
                        </v-card-subtitle>

                        <v-card-actions>
                          <v-btn
                              color="orange lighten-2"
                              text
                              @click="dialog = false"
                              :to="'/' + product.type + '/' + product.id"
                          >
                            More
                          </v-btn>
                          <v-btn
                              v-if="product.type == 'book'"
                              color="primary lighten-2"
                              text
                              @click="dialog = false"
                              :to="'/order/'+product.id"
                          >
                            Buy
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                              icon
                              @click.stop.prevent="show == product.id ? show = null : show = product.id"
                          >
                            <v-icon>{{ show == product.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                          <div v-if="isAuthenticated">
                            <v-btn text class="text--secondary" @click="toggleBookmark(product)"
                                   v-if="product.bookmarked">
                              <v-icon>mdi-bookmark</v-icon>
                            </v-btn>
                            <v-btn text class="text--secondary" @click="toggleBookmark(product)" v-else>
                              <v-icon>mdi-bookmark-outline</v-icon>
                            </v-btn>
                          </div>
                        </v-card-actions>

                        <v-expand-transition>
                          <div v-show="show == product.id">
                            <v-divider></v-divider>
                            <v-card-text>
                              {{ product.content }}
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="this.searchResult != null && !isLoading">
                    <v-col sm="12" xs="12">
                      <v-pagination
                          v-if="searchResult.meta != null"
                          v-model="searchResult.meta.current_page"
                          :length="searchResult.meta.last_page"
                          circle
                          @input="search(searchResult.meta.current_page)"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-container>
            </v-card>
          </v-dialog>

          <v-tab to="/login-register" v-if="!isAuthenticated"
                 style="right:2vw!important;position: absolute!important">
            <v-btn
                rounded
                color="#39bf88"
                style="width: 10vw;height: 6vh;  border-radius: 2vw; box-shadow: none;"
                class="text-capitalize">
              <span style="color:white; font-size: 1vw; font-family: Blinker-SemiBold,serif">Login/Register</span>
            </v-btn>
          </v-tab>
          <div v-if="isAuthenticated && !isMobile" style="right:2vw;position: absolute;">
            <v-menu offset-y rounded="lg" style="z-index: 99999;">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    height="4vh"
                    text
                    v-bind="attrs"
                    v-on="on"
                    style="color:#ffffff;font-size:1vw!important"
                    class="text-capitalize mt-4"
                >
                  <img class="mr-2" src="@/assets/icons/user-icon.svg" alt="avatar"
                       style="height:3.2vw;width:3.2vw;border-radius:200px;background-color:#d3d3d3;border-style:solid;border-color:#c4c4c4;border-width:3px;">
                  <span style="color:#6d6d6d;font-size:1vw">
                                      {{ user['first_name'] + ' ' + user['last_name'] }}
                  </span>
                  <v-icon color="#6d6d6d">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item style="height: 50px!important;" @click="$router.push('profile')">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">Personal Profile</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="$router.push('change-password')">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">Change Password</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="$router.push('orders')">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-basket</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">Orders</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" v-if="user.has_backup" @click="snackbar2=true">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#34c288">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText" style="color: #34c288">Restore Records</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="snackbar=true">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="red">mdi-alert</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                      style="color: #ff4949; font-family: 'Blinker-SemiBold'; letter-spacing: normal; font-size: 16px; display: flex; justify-content: start">
                    Reset
                  </v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="$router.push('/my-page')">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-home</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">My Page</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="logout">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">Log Out</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="$router.push('/notes-index')">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-note-multiple-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">Notes</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="$router.push('/bookmarks-index')">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon color="#a8a8a8">mdi-bookmark-multiple-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">Bookmarks</v-list-item-title>
                </v-list-item>
                <v-list-item style="height: 50px!important;" @click="openNotificationsDialog">
                  <v-list-item-icon style="margin-right: 10px!important;">
                    <v-icon size="20" color="#a8a8a8">mdi-message-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="userDropDownText">
                    Notifications
                    <v-btn style="box-shadow: none; width: 20px; height: 20px; font-size: 18px" fab class="white--text"
                           color="#ff4949" v-if="notifsUnreadCount">{{ notifsUnreadCount }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-snackbar
            max-width="1000"
            centered
            v-model="snackbar"
            timeout="40000"
        >
          <v-row>
            <v-col sm="1" md="4"></v-col>
            <v-col>
              <span style="font-size:45px;color:#ffbe66">
                              Are you sure ?
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size:40px" class="mt-2">
                                This will clear all your information in our system.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <span style="font-size:30px" class="mt-2">
                                Of course, you can return them later.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn large color="red" @click="reset">Yes</v-btn>
              <v-btn large @click="snackbar = false" color="success">No</v-btn>
            </v-col>
          </v-row>
          <template v-slot:action="{ attrs }">
            <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
            max-width="1000"
            centered
            v-model="snackbar2"
            timeout="40000"
        >
          <v-row>
            <v-col sm="1" md="4"></v-col>
            <v-col>
              <span style="font-size:45px;color:#ffbe66">
                              Are you sure ?
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size:40px" class="mt-2">
                                This will restore all your information and records.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <span style="font-size:30px" class="mt-2">
                                Of course, you can reset your account later.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn large color="red" @click="backup">Yes</v-btn>
              <v-btn large @click="snackbar2 = false" color="success">No</v-btn>
            </v-col>
          </v-row>
          <template v-slot:action="{ attrs }">
            <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="snackbar2 = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>

    <v-dialog
        v-model="notificationsDialog"
        width="500"
        transition="dialog-bottom-transition"
        style="z-index: 99999!important;"
    >
      <v-card width="500px">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="notif in notifs" :key="notif.id">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="10" style="align-self: center">
                  <v-icon v-if="!notif.is_seen" color="red">mdi-star</v-icon>
                  {{ notif.title }}
                </v-col>
                <v-col cols="2" style="text-align: right">
                  <v-btn x-small :to="notif.url" v-if="notif.url" color="#febb69" class="white--text"
                         @click="notificationsDialog = false">Open Link
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="text-align: left">
              {{ notif.description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: ['isAuthenticated', 'user', 'isMobile', 'liveReview', 'resources', 'abouts'],
  data: () => {
    return {
      msg: '',
      condition: false,
      snackbar: false,
      snackbar2: false,
      dialog: false,
      searchInput: null,
      searchResult: null,
      isLoading: null,
      show: null,
      menu1: false,
      menu2: false,
      notificationsDialog: false,
      notifs: [],
      notifsUnreadCount: 0,
      timeOutMenu: null
    }
  },
  methods: {
    turnOffMenu() {
      let self = this
      this.timeOutMenu = window.setTimeout(function () {
        self.menu1 = false
      }, 2500)
    },
    continueMenu() {
      window.clearTimeout(this.timeOutMenu)
      let self = this
      this.timeOutMenu = window.setTimeout(function () {
        self.menu1 = false
      }, 2500)
    },
    logout() {
      window.axios.get('api/auth/logout').then(() => {
        this.$cookies.remove('token')
        this.$router.go(0)
      })
    },
    reset() {
      this.snackbar = false
      window.axios.get('api/reset').then((res) => {
        this.msg = res.data.data.message
      })
    },
    backup() {
      this.snackbar2 = false
      window.axios.get('api/backup').then((res) => {
        this.msg = res.data.data.message
      })
    },
    search(page = 1) {
      page = typeof page == 'string' ? 1 : page
      this.isLoading = true;
      window.axios.post('api/search?page=' + page, {
        searchInput: this.searchInput
      }).then((res) => {
        this.searchResult = res.data
        this.isLoading = false
      })
    },

    async openNotificationsDialog() {
      this.notificationsDialog = true
      await this.getNotifications()
      window.axios.post('api/notifs/read')
          .then(() => {
            this.notifsUnreadCount = 0
          })
    },

    getNotifications() {
      window.axios.get('api/notifs').then((res) => {
        this.notifsUnreadCount = res.data.data.unread_count
        this.notifs = res.data.data.notifs
      });
    },
  },

  mounted() {
    this.getNotifications()
  }
}
</script>

<style scoped>
>>> .v-menu__content {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.v-list-item__title, .v-list-item__subtitle {
  flex: unset !important;
}

.activeTab {
  font-family: Blinker-Bold;
  color: #559f81 !important;
}

.activeTab:hover {
  font-family: Blinker-Bold;
  color: #ffffff !important;
}

.v-tab {
  font-family: Blinker-SemiBold;
  font-size: 16px;
  letter-spacing: normal;
  color: white !important;
}

.tabs-content {
  color: #a8a8a8 !important;
  font-size: 1vw !important;
}

.tabs-content-active {
  color: #559f81 !important;
  font-size: 1vw !important;
}

.v-tab:hover {
}

.navBtn {
  text-transform: none !important;
  letter-spacing: normal;
  font-family: Blinker-SemiBold;
  color: #a8a8a8;
  font-size: 16px;
  margin: 0;
}

.userDropDownText {
  display: flex;
  justify-content: start;
  color: #6d6d6d;
  font-family: 'Blinker-SemiBold';
  letter-spacing: normal;
  font-size: 16px;
}

</style>