<template>
  <div>
    <div class="menu-mobile-container">
      <v-row>
        <v-col cols="9" class="text-left pl-10">
          <img
              class="mt-1"
              alt="logo"
              @click="$router.push('/')"
              style="cursor: pointer;width: 115px;height: 60px;"
              src="@/assets/BestAid.svg"
          >
        </v-col>
        <v-col cols="3" class="pr-3 pt-6">
          <v-icon @click="closeMenu" x-large>mdi-close</v-icon>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="4" class="pl-10">
              <v-btn width="80" elevation="0" color="#39bf88" rounded class="text-capitalize white--text" to="/plans"
              >
                buy
              </v-btn>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="2" class="text-right">
              <img
                  class="mt-1"
                  alt="search"
                  width="25"
                  height="25"
                  style="cursor: pointer"
                  src="@/assets/search.png"
              >
            </v-col>
            <v-col cols="2">
              <img
                  class="mt-1"
                  alt="buy"
                  width="25"
                  height="25"
                  style="cursor: pointer"
                  src="@/assets/buy.png"
                  @click="$router.push('/plans')"
              >
            </v-col>
            <v-col cols="2" class="text-left">
              <img
                  class="mt-1"
                  alt="question"
                  width="20"
                  height="30"
                  style="cursor: pointer"
                  src="@/assets/question.png"
                  @click="$router.push('/help')"
              >
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu"
                   @click="$router.push('/profile')"
              >
                Personal Profile
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu"
                   @click="$router.push('/change-password')"
              >
                Change Password
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu"
                   @click="$router.push('/orders')"
              >
                Orders
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu" style="color: red!important"
                   @click="snackbar = true"
              >
                Reset
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu" @click="$router.push('/my-page')">
                My Page
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu" @click="logout">
                Log Out
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-menu" @click="$router.push('/notes-index')">
                Notes
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1" @click="$router.push('/bookmarks-index')">
              <div class="title-menu">
                Bookmarks
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1" @click="openNotificationsDialog">
              <div class="title-menu">
                Notifications
              </div>
            </v-col>
            <v-col cols="12" class="mb-0 mt-4 py-1">
              <div class="title-secondary-menu" @click="$router.push('/')">
                Home
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-secondary-menu" @click="$router.push('/products')">
                Products
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-secondary-menu" @click="$router.push('/live-reviews')">
                Live Review
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-secondary-menu" @click="$router.push('/medical-schools')">
                Medical Schools
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <div class="title-secondary-menu" @click="$router.push('/about/2')">
                About Us
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1" @click="$router.push('/contact')">
              <div class="title-secondary-menu">
                Contact Us
              </div>
            </v-col>
            <v-col cols="12" class="my-0 py-1" @click="$router.push('/blog')">
              <div class="title-secondary-menu">
                Resources
              </div>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
      <v-snackbar
          centered
          v-model="snackbar"
          timeout="40000"
      >
        <v-row>
          <v-col>
              <span style="color:#ffbe66">
                              Are you sure ?
              </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <span class="mt-2">
                                This will clear all your information in our system.
              </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="mt-2">
                                Of course, you can return them later.
              </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="red" @click="reset">Yes</v-btn>
            <v-btn @click="snackbar = false" color="success">No</v-btn>
          </v-col>
        </v-row>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  props: ['isAuthenticated', 'user'],
  data: () => {
    return {
      msg: '',
      snackbar: false,
      dialog: false,
    }
  },
  methods: {
    async openNotificationsDialog() {
      this.notificationsDialog = true
      await this.getNotifications()
      window.axios.post('api/notifs/read')
          .then(() => {
            this.notifsUnreadCount = 0
          })
    },
    getNotifications() {
      window.axios.get('api/notifs').then((res) => {
        this.notifsUnreadCount = res.data.data.unread_count
        this.notifs = res.data.data.notifs
      });
    },
    closeMenu() {
      this.$emit('closeMenu')
    },
    logout() {
      window.axios.get('api/auth/logout').then(() => {
        this.$cookies.remove('token')
        this.$router.go(0)
      })
    },
    reset() {
      this.snackbar = false
      window.axios.get('api/reset').then((res) => {
        this.msg = res.data.data.message
      })
    },
    backup() {
      this.snackbar2 = false
      window.axios.get('api/backup').then((res) => {
        this.msg = res.data.data.message
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
.menu-mobile-container {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 87%;
  height: 100%;
  background-color: #fbfbfd !important;
}

.title-menu {
  color: #6d6d6d;
  font-family: Blinker-Bold !important;
}

.title-secondary-menu {
  color: #6d6d6d;
  font-family: Blinker-Regular !important;
}
</style>