import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import vueDebounce from 'vue-debounce'
import fullscreen from 'vue-fullscreen'

Vue.use(vueDebounce)
Vue.use(fullscreen)

Vue.use(mdiVue, {
    icons: mdijs
})
Vue.config.productionTip = false

Vue.mixin({
    data() {
        return {
            NOTIFICATION: '',
            NOTIFICATION_STATUS: false,
            NOTIFICATION_COLOR: '',
            mobile: window.innerWidth <= 700,
            baseURL: process.env.VUE_APP_BASE_URL,
        }
    },
    methods: {
        toggleBookmark(product) {
            window.axios.post(`api/bookmarks/${product.id}`).then(() => {
                product.bookmarked = !product.bookmarked
            })
        }
    },
    watch: {
        NOTIFICATION_STATUS: function () {
            setTimeout(() => {
                this.NOTIFICATION_STATUS = false
            }, 3000)
        }
    }
})

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
