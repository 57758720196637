<template>
  <div
      @click="mini = !mini"
  >
    <v-navigation-drawer
        style="z-index: 999999999"
        mini-variant-width="111.3px"
        width="14vw"
        :mini-variant="mini"
        permanent
        absolute
        v-model="drawer"
        flat
    >
      <v-list
          nav
          style="padding: 0!important;"
      >
        <v-list-item link @click="$router.push('/my-page'); mini = !mini"
                     :style="{minWidth: '50px', height: '50px', marginLeft: '28.5px', marginTop: '30px', marginRight: '28.5px', borderRadius: '42px', marginBottom: '100px', backgroundColor: '#f2f3f7', color: '#a8a8a8!important', fontFamily: 'Blinker-SemiBold'  }">
          <v-list-item-icon :class="mini ? '' : 'icon-custom-left-padding'" style="margin: auto">
            <v-icon color="#a8a8a8">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="font-size: 0.8vw; letter-spacing:normal;padding-left: 1.5vw!important;text-align:left!important;">
            My Page
          </v-list-item-title>
        </v-list-item>

        <v-menu
            nudge-right="10px"
            v-model="bookMenu"
            bottom
            offset-x
            rounded="lg"
            transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
<!--            @mouseenter="bookMenu = true; animationMenu = false; videoMenu = false"-->
            <v-list-item

                v-bind="attrs" v-on="on"
                :style="{minWidth: '50px', height: '50px', marginLeft: '28.5px', marginRight: '28.5px', marginBottom: '30px', borderRadius: '10px', backgroundColor: $route.path == '/books' ? '#34c288' : '#f2f3f7', color: $route.path == '/books' ? '#ffffff!important' : '#a8a8a8!important', fontFamily: 'Blinker-SemiBold' }"
                @click="redirectTo('/books')" link>
              <v-list-item-icon :class="mini ? '' : 'icon-custom-left-padding'" style="margin: auto">
                <v-icon :color="$route.path == '/books' ? '#ffffff' : '#34c288'">mdi-bookshelf</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                  style="font-size: 0.8vw; letter-spacing:normal;padding-left: 1.5vw!important;text-align:left!important;">
                My Book
              </v-list-item-title>
            </v-list-item>
          </template>

<!--          <sidebar-hover-menu :categories="categories" type="book" v-on:closeMenu="closeMenu"></sidebar-hover-menu>-->
        </v-menu>

        <v-menu
            nudge-right="10px"
            v-model="animationMenu"
            bottom
            offset-x
            rounded="xl"
            transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
<!--            @mouseenter="animationMenu = true; bookMenu = false; videoMenu = false"-->

            <v-list-item
                v-bind="attrs" v-on="on"
                :style="{minWidth: '50px', height: '50px', marginLeft: '28.5px', marginRight: '28.5px', marginBottom: '30px', borderRadius: '10px', backgroundColor: $route.path == '/animations' ? '#ffbe66' : '#f2f3f7', color: $route.path == '/animations' ? '#ffffff!important' : '#a8a8a8!important', fontFamily: 'Blinker-SemiBold' }"
                @click="redirectTo('/animations')"
                link>
              <v-list-item-icon :class="mini ? '' : 'icon-custom-left-padding'" style="margin: auto">
                <v-icon :color="$route.path == '/animations' ? '#ffffff' : '#ffbe66'">mdi-animation</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                  style="font-size: 0.8vw; letter-spacing:normal;padding-left: 1.5vw!important;text-align:left!important;">
                Animation
              </v-list-item-title>
            </v-list-item>
          </template>

<!--          <sidebar-hover-menu :categories="categories" type="animation" v-on:closeMenu="closeMenu"></sidebar-hover-menu>-->
        </v-menu>

        <v-menu
            nudge-right="10px"
            v-model="videoMenu"
            bottom
            offset-x
            rounded="xl"
            transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
<!--            @mouseenter="videoMenu = true; bookMenu = false; animationMenu = false"-->
            <v-list-item
                v-bind="attrs" v-on="on"
                :style="{minWidth: '50px', height: '50px', marginLeft: '28.5px', marginRight: '28.5px', marginBottom: '30px', borderRadius: '10px', backgroundColor: $route.path == '/videos' ? '#ff8f4e' : '#f2f3f7', color: $route.path == '/videos' ? '#ffffff!important' : '#a8a8a8!important', fontFamily: 'Blinker-SemiBold' }"
                @click="redirectTo('/videos')" link>
              <v-list-item-icon :class="mini ? '' : 'icon-custom-left-padding'" style="margin: auto">
                <v-icon :color="$route.path == '/videos' ? '#ffffff' : '#ff8f4e'">mdi-video</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                  style="font-size: 0.8vw; letter-spacing:normal;padding-left: 1.5vw!important;text-align:left!important;">
                Video Library
              </v-list-item-title>
            </v-list-item>
          </template>

<!--          <sidebar-hover-menu :categories="categories" type="video" v-on:closeMenu="closeMenu"></sidebar-hover-menu>-->
        </v-menu>

        <v-list-item
            :style="{minWidth: '50px', height: '50px', marginLeft: '28.5px', marginRight: '28.5px', marginBottom: '30px', borderRadius: '10px', backgroundColor: $route.path == '/questions/new' ? '#34c288' : '#f2f3f7', color: $route.path == '/questions/new' ? '#ffffff!important' : '#a8a8a8!important', fontFamily: 'Blinker-SemiBold' }"
            @click="$router.push('/questions/new'); mini = !mini" link>
          <v-list-item-icon :class="mini ? '' : 'icon-custom-left-padding'" style="margin: auto">
            <v-icon :color="$route.path == '/questions/new' ? '#ffffff' : '#34c288'">mdi-help</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="font-size: 0.8vw; letter-spacing:normal;padding-left: 1.5vw!important;text-align:left!important;">
            Q-Bank
          </v-list-item-title>
        </v-list-item>

        <v-list-item
            :style="{minWidth: '50px', height: '50px', marginLeft: '28.5px', marginRight: '28.5px', marginBottom: '30px', borderRadius: '10px', backgroundColor: '#f2f3f7', color: '#a8a8a8!important', fontFamily: 'Blinker-SemiBold' }"
            @click="goToLiveReview();mini = !mini" link>
          <v-list-item-icon :class="mini ? '' : 'icon-custom-left-padding'" style="margin: auto">
            <v-img :class="mini ?  'closed-live-review' : 'opened-live-review'" src="@/assets/icons/LiveReviewMenu.svg"
                   alt="Live Review"></v-img>
          </v-list-item-icon>
          <v-list-item-title
              style="font-size: 0.8vw; letter-spacing:normal;padding-left: 0.5vw!important;text-align:left!important;">
            Live Review
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import SidebarHoverMenu from "./SidebarHoverMenu";

export default {
  name: "SideBar",
  // components: {SidebarHoverMenu},
  data() {
    return {
      drawer: false,
      mini: true,
      categories: {},

      bookMenu: false,
      animationMenu: false,
      videoMenu: false,
      // liveReview: null,
    }
  },
  methods: {
    goToLiveReview() {
      this.$router.push('/live-review-list')
    },
    getCategories() {
      window.axios.get('api/categories?with-all-relation')
          .then((res) => {
            this.categories = res.data.data
          })
    },
    redirectTo(path) {
      if (this.$route.path !== '/' && path.includes(this.$route.path)) {
        window.location.replace(path)
      } else {
        this.$router.push(path)
      }
    },
    closeMenu(menu) {
      this.$data[menu] = false
    }
  },
  mounted() {
    // window.axios.get('api/live-review').then((res) => {
    //   this.liveReview = res.data.data
    // });
    this.getCategories()
  }
}
</script>

<style>
.v-navigation-drawer {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.closed-live-review {
  margin-top: -2px !important;
  margin-left: 5px !important;
  width: 60px !important;
  height: 60px !important;
}

.opened-live-review {
  margin-top: -2px !important;
  width: 60px !important;
  height: 60px !important;
}

.icon-custom-left-padding {
  padding-left: 8px !important
}

/*.v-menu__content {
  margin-left: 5px!important;
}*/
</style>