import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbarStatus: false,
        snackbarText: '',
        snackbarColor: 'success',
        user: null,
        auth: false,
        loading: true,
    },
    getters: {},
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setAuth(state, auth) {
            state.auth = auth;
        },
        setSnackbarStatus(state, snackbarStatus) {
            state.snackbarStatus = snackbarStatus;
        },
        setSnackbarText(state, snackbarText) {
            state.snackbarText = snackbarText;
        },
        setSnackbarColor(state, snackbarColor) {
            state.snackbarColor = snackbarColor;
        },
    },
    actions: {},
    modules: {}
})
