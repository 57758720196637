<template>
  <div>
    <v-row style="width: 100%;" v-bind:style="{ height: this.isMobile ? '150px' : '50px' }"></v-row>
    <div class="footer" v-if="!isMobile" id="footer-desktop" style="padding-top: 50px">
      <v-row class="text-start">
        <v-col id="footer-line-first" sm="1" md="1"></v-col>
        <v-col id="footer-line-logo" md="2" sm="2">
          <img style="max-width: 200px;cursor: pointer!important" @click="$router.push('/')" alt="logo"
               src="@/assets/logo.svg">
        </v-col>
        <v-col id="footer-line-1" md="2">
          <p>Products</p>
          <v-btn style="padding: 0;!important;" text class="text-capitalize" @click="$router.push('/products')">
            <span class="customBtn">USMLE Step 1</span>
          </v-btn>
        </v-col>
        <v-col id="footer-line-2" md="2">
          <p>About us</p>
          <v-btn style="padding: 0;!important;" @click="$router.push('/about/1')" text class="text-capitalize">
            <span class="customBtn">{{ abouts != null ? abouts[0].title : '' }}</span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" @click="$router.push('/about/2')" text class="text-capitalize">
            <span class="customBtn">{{ abouts != null ? abouts[1].title : '' }}</span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" @click="$router.push('/about/3')" text class="text-capitalize">
            <span class="customBtn">{{ abouts != null ? abouts[2].title : '' }}</span>
          </v-btn>
        </v-col>
        <v-col id="footer-line-3" md="2">
          <v-btn style="padding: 0" x-small height="35px"
                 @click="$router.push('/contact')" text class="text-capitalize">
            <span style="font-size: 16px; font-family: Blinker-Regular;
           letter-spacing: normal;">
            Contact Us
            </span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" text class="text-capitalize">
            <span class="customBtn">
               info@bestaidreviews.com
            </span>
          </v-btn>
        </v-col>
        <v-col id="footer-line-icons" md="2">
          <p>Follow Us</p>
          <a :href="'https://'+social.facebook">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-facebook</v-icon>
          </a>
          <a :href="'https://'+social.youtube">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-youtube</v-icon>
          </a>
          <a :href="'https://'+social.instagram">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-instagram</v-icon>
          </a>
          <a :href="'https://'+social.pinterest">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-pinterest</v-icon>
          </a>
          <a :href="'https://'+social.twitter">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-twitter</v-icon>
          </a>
        </v-col>
        <v-col id="footer-line-last" md="1" sm="1"></v-col>
      </v-row>
      <v-row class="text-start">
        <v-col id="footer2-line-first" sm="1" md="1"></v-col>
        <v-col id="footer2-line-logo" md="2" sm="2"></v-col>
        <v-col id="footer2-line-1" md="2">
          <p>Live Review</p>
          <v-btn style="padding: 0" :href="liveReview" text class="text-capitalize"><span
              class="customBtn">USMLE Step 1</span></v-btn>
        </v-col>
        <v-col id="footer2-line-2" md="2">
          <p style="cursor: pointer" @click="$router.push('/blog')">Resources</p>
          <!--          <v-btn style="padding: 0;!important;" @click="$router.push('/resources/1')" text class="text-capitalize">-->
          <!--            <span class="customBtn">{{ this.resources != null ? this.resources[0].title : '' }}</span>-->
          <!--          </v-btn>-->
          <!--          <br>-->
          <!--          <v-btn style="padding: 0;!important;" @click="$router.push('/resources/2')" text class="text-capitalize">-->
          <!--            <span class="customBtn">{{ this.resources != null ? this.resources[1].title : '' }}</span>-->
          <!--          </v-btn>-->
        </v-col>
        <v-col id="footer2-line-3" md="2"></v-col>
        <v-col id="footer2-line-icons" md="2">
          <!--          <a :href="'https://'+social.apple">-->
          <!--            <img height="90px" src="@/assets/apple.png">-->
          <!--          </a>-->
        </v-col>
        <v-col id="footer2-line-last" md="1" sm="1"></v-col>
      </v-row>
      <v-row class="text-start">
        <v-col id="footer3-line-first" sm="1" md="1"></v-col>
        <v-col id="footer3-line-logo" md="2" sm="2"></v-col>
        <v-col id="footer3-line-1" md="2">
          <v-btn style="padding: 0" @click="$router.push('/medical-schools')"
                 text class="text-capitalize">
            <span style="font-size: 16px; font-family: Blinker-Regular;
           letter-spacing: normal;">
            Medical Schools
            </span>
          </v-btn>
        </v-col>
        <v-col id="footer3-line-2" md="2">
          <v-btn style="padding: 0" x-small height="35px"
                 @click="$router.push('/help')" text class="text-capitalize">
            <span style="font-size: 16px; font-family: Blinker-Regular;
           letter-spacing: normal;">
            Help
            </span>
          </v-btn>
        </v-col>
        <v-col id="footer3-line-3" md="2"></v-col>
        <v-col id="footer3-line-icons" md="2">
          <!--          <a :href="'https://'+social.google">-->
          <!--            <img style="margin-top: -20px!important;" height="90px" src="@/assets/google.png">-->
          <!--          </a>-->
        </v-col>
        <v-col id="footer3-line-last" md="1" sm="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="text--disabled">
            The United States Medical Licensing Examination (USMLE@) is a joint program of the Federation of the State
            Medical Boards (FSMB@ and National Board of Medical Examiners (NBME@)
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="text--disabled">
            Best Aid Reviews, LLC
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="footer" v-else id="footer-mobile">
      <v-row>
        <v-col cols="2"></v-col>
        <v-col id="footer-line-logo">
          <img style="max-width: 200px;cursor: pointer!important" @click="$router.push('/')" alt="logo"
               src="@/assets/logo.svg">
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="footer-line-1">
          <p>Products</p>
          <v-btn style="padding: 0;!important;" @click="$router.push('/products')" text class="text-capitalize">
            <span class="customBtn">USMLE Step 1</span>
          </v-btn>
        </v-col>
        <v-col id="footer-line-2">
          <p>About us</p>
          <v-btn style="padding: 0;!important;" @click="$router.push('/about/1')" text class="text-capitalize">
            <span class="customBtn">{{ abouts != null ? abouts[0].title : '' }}</span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" @click="$router.push('/about/2')" text class="text-capitalize">
            <span class="customBtn">{{ abouts != null ? abouts[1].title : '' }}</span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" @click="$router.push('/about/2')" text class="text-capitalize">
            <span class="customBtn">{{ abouts != null ? abouts[2].title : '' }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="footer2-line-1">
          <p>Live Review</p>
          <v-btn style="padding: 0" :href="liveReview" text class="text-capitalize">
            <span class="customBtn">USMLE Step 1</span>
          </v-btn>
        </v-col>
        <v-col id="footer2-line-2">
          <p>Resources</p>
          <v-btn style="padding: 0;!important;" @click="$router.push('/resources/1')" text class="text-capitalize">
            <span class="customBtn">{{ this.resources != null ? this.resources[0].title : '' }}</span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" @click="$router.push('/resources/2')" text class="text-capitalize">
            <span class="customBtn">{{ this.resources != null ? this.resources[1].title : '' }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col id="footer-line-3">
          <v-btn style="padding: 0" @click="$router.push('/contact')" text class="text-capitalize">
            <span style="font-size: 16px; font-family: Blinker-Regular;
           letter-spacing: normal;">Contact Us</span>
          </v-btn>
          <br>
          <v-btn style="padding: 0;!important;" text class="text-capitalize">
            <span class="customBtn">
               info@bestaidreviews.com
            </span>
          </v-btn>
        </v-col>
        <v-col id="footer3-line-1">
          <v-btn style="padding: 0" @click="$router.push('/medical-schools')" text class="text-capitalize">
            <span style="font-size: 16px; font-family: Blinker-Regular;
           letter-spacing: normal;">Medical Schools</span>
          </v-btn>
        </v-col>
        <v-col id="footer3-line-2">
          <v-btn style="padding: 0" @click="$router.push('/help')" text class="text-capitalize">
            <span style="font-size: 16px; font-family: Blinker-Regular;
           letter-spacing: normal;">Help</span>
          </v-btn>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="footer-line-icons">
          <p>Follow Us</p>
          <a :href="'https://'+social.facebook">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-facebook</v-icon>
          </a>
          <a :href="'https://'+social.youtube">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-youtube</v-icon>
          </a>
          <a :href="'https://'+social.instagram">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-instagram</v-icon>
          </a>
          <a :href="'https://'+social.pinterest">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-pinterest</v-icon>
          </a>
          <a :href="'https://'+social.twitter">
            <v-icon color="#a8a8a8" style="font-size: 30px!important;" class="pr-3">mdi-twitter</v-icon>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="footer2-line-icons" md="2">
          <!--          <a :href="'https://'+social.apple">-->
          <!--            <img height="45px" src="@/assets/apple.png">-->
          <!--          </a>-->
        </v-col>
        <v-col id="footer2-line-icons" md="2">
          <!--          <a :href="'https://'+social.google">-->
          <!--            <img height="45px" src="@/assets/google.png">-->
          <!--          </a>-->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="text--disabled">
            The United States Medical Licensing Examination (USMLE@) is a joint program of the Federation of the State
            Medical Boards (FSMB@ and National Board of Medical Examiners (NBME@)
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="text--disabled">
            Best Aid Reviews, LLC
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: () => {
    return {
      social: []
    }
  },
  props: ['isMobile', 'liveReview', 'resources', 'abouts'],
  mounted() {
    window.axios.get('api/social').then((res) => {
      this.social = res.data.data
    })
  },
  computed: {
    liveReviewName: function () {
      if (this.liveReview != null) {
        if (this.liveReview.includes('www.')) {
          return this.liveReview.split('.')[1]
        } else if (this.liveReview.includes('//')) {
          return this.liveReview.split('//')[1]
        }
        return this.liveReview
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #f2f3f7;
}

p {
  font-size: 16px;
  font-family: Blinker-Regular;
  letter-spacing: normal;
  margin: 0;
}

.customBtn {
  font-family: Blinker-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a8a8a8;
  margin: 0;
  padding: 0;
}
</style>