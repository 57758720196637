<template>
  <div>
    <div>
      <v-row>
        <v-col cols="1">
          <img
              v-if="$store.state.auth"
              class="mt-6 ml-2"
              alt="logo"
              @click="menuHandler = !menuHandler"
              style="cursor: pointer;width: 28px;height: 20px;"
              src="@/assets/menu-mobile.png"
          >
        </v-col>
        <v-col cols="2">
          <v-btn
              height="4vh"
              text
              style="color:#ffffff;font-size:1vw!important"
              class="text-capitalize mt-4"
          >
            <img class="mr-1 mt-1" src="@/assets/icons/user-icon.svg" alt="avatar"
                 style="height:8.7vw;width:8.7vw;border-radius:200px;background-color:#d3d3d3;border-style:solid;border-color:#c4c4c4;border-width:2px;">
            <span style="color:#6d6d6d;font-size:3.5vw">
                                      {{ user['first_name'] + ' ' + user['last_name'] }}
          </span>
          </v-btn>
        </v-col>
        <v-col></v-col>
        <v-col cols="5">
          <img
              class="mt-1"
              alt="logo"
              @click="$router.push('/')"
              style="cursor: pointer;width: 115px;height: 60px;"
              src="@/assets/BestAid.svg"
          >
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-divider style="background-color: #868688!important"></v-divider>
          <v-divider style="background-color: #868688!important"></v-divider>
          <v-divider style="background-color: #868688!important"></v-divider>
        </v-col>
      </v-row>
    </div>
    <div>
      <mobile-menu @closeMenu="menuHandler = false" v-if="menuHandler"></mobile-menu>
    </div>
  </div>
</template>

<script>
import MobileMenu from "../menu/MobileMenu.vue";

export default {
  name: "MobileDefaultHeader",
  components: {MobileMenu},
  props: ['isAuthenticated', 'user'],
  data: () => {
    return {
      msg: '',
      snackbar: false,
      dialog: false,
      menuHandler: false
    }
  },
  methods: {
    openMenu() {

    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>