var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentPath && !_vm.$store.state.loading)?_c('v-app',[(_vm.screenType === 'desktop')?_c('div',[_c('div',{style:({ marginTop: _vm.mobile && _vm.$store.state.auth ? '100px' : '0' })},[(_vm.$store.state.auth && _vm.currentPath !== '/questions/exam' && _vm.currentPath.indexOf('/exam/predefined/') === -1 && _vm.currentPath !== '/questions/review')?_c('side-bar',{ref:"sidebarComponent",attrs:{"mobile":_vm.mobile}}):_vm._e(),_c('div',{style:({ marginLeft: _vm.$store.state.auth && !_vm.mobile ? '111.3px' : '0'})},[(!(
                  _vm.currentPath === '/my-page' ||
                  _vm.currentPath === '/questions/prior' ||
                  _vm.currentPath === '/questions/performance' ||
                  _vm.currentPath === '/questions/notes' ||
                  _vm.currentPath === '/questions/search'||
                  _vm.currentPath === '/questions/help'||
                  _vm.currentPath === '/questions/exam' ||
                  _vm.currentPath === '/questions/review' ||
                  _vm.currentPath.indexOf('/exam/predefined/') !== -1 ||
                  _vm.currentPath.indexOf('/animation/') !== -1 ||
                  _vm.currentPath.indexOf('/video/') !== -1 ||
                  _vm.currentPath.indexOf('/book/') !== -1 ||
                  _vm.$route.path === '/books' ||
                  _vm.$route.path === '/questions/new' ||
                  _vm.$route.path === '/animations' ||
                  _vm.$route.path === '/videos'))?_c('nav-bar',{attrs:{"is-mobile":_vm.mobile,"isAuthenticated":_vm.$store.state.auth,"user":_vm.$store.state.user,"live-review":_vm.liveReview,"resources":_vm.resources,"abouts":_vm.abouts}}):(_vm.currentPath !== '/questions/exam' && _vm.currentPath !== '/questions/review' && _vm.currentPath.indexOf('/exam/predefined/') === -1)?_c('short-nav-bar',{attrs:{"is-mobile":_vm.mobile,"isAuthenticated":_vm.$store.state.auth,"user":_vm.$store.state.user}}):_vm._e(),_c('div',[_c('router-view')],1),(_vm.currentPath !== '/questions/exam' && _vm.currentPath !== '/questions/review' && _vm.currentPath.indexOf('/exam/predefined/') === -1 && _vm.currentPath !== '/login-register')?_c('Footer',{attrs:{"live-review":_vm.liveReview,"resources":_vm.resources,"is-mobile":_vm.mobile,"abouts":_vm.abouts}}):_vm._e()],1)],1)]):_c('div',{attrs:{"id":_vm.currentPath == '/questions/exam' ? '' : 'over-flow-x-y-hidden'}},[_c('div',[((
        _vm.currentPath === '/login-register' ||
        _vm.currentPath === '/forgot-password'||
        _vm.currentPath === '/') && !_vm.$store.state.auth
      )?_c('mobile-auth-header'):_vm._e(),(_vm.$store.state.auth && this.currentPath !== '/my-page')?_c('mobile-default-header',{attrs:{"user":_vm.$store.state.user}}):_vm._e(),_c('router-view'),(
        _vm.currentPath === '/login-register'||
        _vm.currentPath === '/forgot-password' ||
        _vm.currentPath === '/' ||
        _vm.currentPath === '/my-page'||
        _vm.currentPath === '/products'||
        _vm.currentPath === '/demo'
      )?_c('mobile-text-footer'):_vm._e(),(
        _vm.currentPath === '/questions/new'||
        _vm.currentPath === '/questions/prior'||
        _vm.currentPath === '/questions/notes'||
        _vm.currentPath === '/questions/search'||
        _vm.currentPath === '/questions/performance'||
        _vm.currentPath === '/questions/help'||
        _vm.currentPath === '/books'||
        _vm.currentPath === '/videos' ||
        _vm.currentPath === '/animations'||
        _vm.currentPath === '/bookmarks-index'||
        _vm.currentPath.indexOf('/animation/') !== -1 ||
        _vm.currentPath.indexOf('/video/') !== -1 ||
        _vm.currentPath.indexOf('/book/') !== -1
      )?_c('mobile-icon-footer'):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }