<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="1">
        <img
            class="mt-6 ml-2"
            alt="logo"
            @click="menuHandler = !menuHandler"
            style="cursor: pointer;width: 28px;height: 20px;"
            src="@/assets/menu-mobile.png"
        >
      </v-col>
      <v-col cols="2">
        <v-btn @click="$router.push('/login-register')" height="25" width="120" elevation="0"
               class="text-capitalize ml-4 mt-5 white--text text-left"
               color="#39bf88" style="border-radius: 28.5px">login/Register
        </v-btn>
      </v-col>
      <v-col></v-col>
      <v-col cols="5">
        <img
            class="mt-1"
            alt="logo"
            @click="$router.push('/')"
            style="cursor: pointer;width: 115px;height: 60px;"
            src="@/assets/BestAid.svg"
        >
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-divider style="background-color: #868688!important"></v-divider>
        <v-divider style="background-color: #868688!important"></v-divider>
        <v-divider style="background-color: #868688!important"></v-divider>
      </v-col>
    </v-row>
    <div>
      <mobile-menu-un-auth @closeMenu="menuHandler = false" v-if="menuHandler"></mobile-menu-un-auth>
    </div>
  </div>
</template>

<script>
import MobileMenuUnAuth from "../menu/MobileMenuUnAuth.vue";

export default {
  name: "MobileAuthHeader",
  components: {MobileMenuUnAuth},
  props: ['isAuthenticated'],
  data: () => {
    return {
      msg: '',
      snackbar: false,
      dialog: false,
      menuHandler: false
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>