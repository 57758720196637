<template>
  <div v-if="!$store.state.loading">
    <div v-if="screenType === 'desktop'">
      <div class="circle"></div>
      <v-container>
        <v-row class="mt-16">
          <v-col sm="12" lg="6" class="z-index-2">
            <v-row>
              <v-col cols="1" class="d-flex justify-end">
                <span class="slash">&nbsp;</span>
              </v-col>
              <v-col cols="11" style="padding-bottom: 0">
                <v-col class="ma-0 pa-0 12 d-flex justify-start">
                  <h1 class="page-title">BEST AID</h1>
                </v-col>
                <v-col class="ma-0 pa-0 12 d-flex justify-start">
                  <h2 class="page-sub-title">REVIEWs</h2>
                </v-col>
                <p class="page-description" style="margin-top: 28.1px">
                  Animated Comprehensive USMLE Step 1 Review <br>
                  Integrated Book, Q-bank, Animations & Videos <br>
                  Empowered by Scanning Technology <br>
                  Live Review Sessions
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="11" class="d-flex justify-start" style="padding-top: 0">
                <v-btn
                    class="mr-6 text-capitalize"
                    color="#39bf88"
                    rounded
                    width="210px"
                    height="60px"
                    to="/plans"
                    style="color:white;font-size: 17px!important; margin-top: 29px!important; box-shadow: none"
                >Buy
                </v-btn>
                <v-btn
                    class="text-capitalize"
                    color="#fdbd68"
                    rounded
                    width="210px"
                    height="60px"
                    style="color:white;font-size: 17px!important; margin-top: 29px!important; box-shadow: none"
                    to="/demo">
                  Demo
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="12" lg="6" class="z-index-2">
            <video-player :component="'home'" style="border-radius: 15px!important;" :id="videoId" v-if="videoLoaded"
                          :options="videoOptions"/>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-16">
          <v-col sm="8">
            <v-card
                class="mt-16"
                elevation="0"
                style="height: 165px; border-radius: 15px"
                color="#f2f3f7"
                dark
                text
            >
              <v-row>
                <v-col cols="5" class="page-description ml-8 pt-5">2000+ High-yield concepts</v-col>
                <v-col cols="4" class="page-description pt-5">2000+ Medical illustrations</v-col>
                <v-col cols="5" class="page-description ml-8">2000+ Animations & lecture videos</v-col>
                <v-col cols="6" class="page-description">2000+ Board-style questions & answers</v-col>
                <v-col cols="12" class="pt-0 mt-0" style="font-size: 30px!important;color: #39bf88!important">
                  “COMPLEX IS SIMPLIFIED!”
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="background-home">
        <v-col md="2"></v-col>
        <v-col md="8">
          <v-row>
            <v-col>
              <!--            <div-->
              <!--                class="qr-code">-->
              <!--              <img width="100%" height="100%" alt="Qrcode" src="../assets/Qr.png"/>-->
              <!--              <br>-->
              <!--              <div style="color:#a8a8a8;font-size:0.8vw;margin-top:1.5vh">Scan QR Code</div>-->
              <!--            </div>-->
              <v-img src="../assets/HomePageBook.png"></v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="2"></v-col>
      </v-row>
      <div class="background-home-bottom">
        <v-container>
          <v-row style="margin-top: -73px!important;">
            <v-col></v-col>
            <v-col
                lg="3"
                md="3"
                sm="12">
              <v-card
                  class="pa-1"
                  to="/products"
                  width="225px"
                  height="225px"
                  style="box-shadow: 0 15px 35px 0 rgba(255, 198, 119, 0.4);border-radius:15px;background: linear-gradient(to bottom right, #ffdeb2 0%, #ffbe66 100%); color: white">
                <v-card-title class="ma-3" style="font-size:22px">Animation</v-card-title>
                <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Animation-Home.svg"></v-img>
              </v-card>
            </v-col>
            <v-col
                lg="3"
                md="3"
                sm="12">
              <v-card
                  class="pa-1"
                  to="/products"
                  width="225px"
                  height="225px"
                  style="box-shadow: 0 15px 35px 0 rgba(78, 199, 149, 0.41);border-radius:15px;background: linear-gradient(to bottom right, #add9c7 0%, #26bf81 100%); color: white">
                <v-card-title class="ma-3" style="font-size:22px">Q-Bank</v-card-title>
                <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Q-Bank-Home.svg"></v-img>
              </v-card>
            </v-col>
            <v-col
                lg="3"
                md="3"
                sm="12">
              <v-card
                  class="pa-1"
                  to="/products"
                  width="225px"
                  height="225px"
                  style="box-shadow: 0 15px 35px 0 rgba(255, 160, 105, 0.4);border-radius:15px;background:linear-gradient(to bottom right, #ffc6a6 0%, #ff8640 100%); color: white">
                <v-card-title class="ma-3" style="font-size:22px">Video</v-card-title>
                <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Video-Home.svg"></v-img>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row class="mt-16">
            <v-col></v-col>
            <v-col cols="9">
              <v-card
                  class="pa-1"
                  to="/live-reviews"
                  :width="windowWidth < 1920 ? '817px' : '1120px'"
                  height="225px"
                  style="box-shadow: 0 15px 35px 0 rgba(78, 199, 149, 0.41);border-radius:15px;background: linear-gradient(to bottom right, #add9c7 0%, #26bf81 100%); color: white">
                <v-card-title class="mt-3 ml-3" style="font-size:22px">Live Review</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-img class="mx-3" width="76px" height="76px" src="@/assets/icons/live-review.png"></v-img>
                    </v-col>
                    <v-col cols="8" class="text-left white--text mt-0 pt-0"
                           style="font-size: 20px!important;line-height: 25px!important">
                      In-Person Intensive: 100 hours (2 weeks) <br>
                      Online Intensive: 100 hours (2 weeks) <br>
                      Online Extended: 150 hours (3,6, or 12 months)
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row style="margin-top: 143px;">
            <v-col cols="12">
            <span style="
                font-family: Blinker-SemiBold;
                font-size: 30px;
                color: #98a2ab;
                line-height: 50.4px;
                letter-spacing: normal
">
              Step 1 Pricing
            </span>
              <br>
              <span style="
                font-family: Blinker-SemiBold;
                font-size: 16px;
                color: #98a2ab;
                line-height: 56.9px;
                letter-spacing: normal
">
              Questions about subscription? Click <span @click="$router.push('/help')"
                                                        style="color: #39bf88">here</span>.
            </span>
            </v-col>
          </v-row>
        </v-container>
        <div class="custom-margin-x">
          <v-tabs hide-slider show-arrows v-model="firstTab" height="145px" centered
                  background-color="transparent" color="#39bf88" class="pt-1 pb-6"
                  active-class="page-tab-active">
            <v-tab class="page-tab-text elevation-10" :style="{ color: firstTab == 0 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:250px!important"><span
                class="page-tab-span text-capitalize">First Time</span>
            </v-tab>
            <v-tab class="page-tab-text elevation-10" :style="{ color: firstTab == 1 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:250px!important"><span
                class="page-tab-span text-capitalize">Renewal</span></v-tab>
            <v-tab class="page-tab-text elevation-10" :style="{ color: firstTab == 2 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:250px!important">
              <span class="page-tab-span text-capitalize">Upgrade</span>
            </v-tab>
          </v-tabs>
          <v-tabs hide-slider show-arrows v-model="secondTab" height="145px" centered
                  background-color="transparent" color="#39bf88" class="pt-1 pb-6"
                  active-class="page-tab-active">
            <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 0 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:300px!important"><span
                class="page-tab-span text-capitalize">BAR</span>
            </v-tab>
            <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 1 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:300px!important"><span
                class="page-tab-span text-capitalize">BAR Bundle</span></v-tab>
            <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 2 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:300px!important"
            ><span
                class="page-tab-span text-capitalize">BAR Live online</span>
            </v-tab>
            <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 2 ? '#39bf88' : '#98a2ab' }"
                   style="height:75px;margin-top: 70px;width:300px!important"
            ><span
                class="page-tab-span text-capitalize">BAR Live in Person</span>
            </v-tab>
          </v-tabs>
          <v-row v-if="plans.length" class="mt-5"
                 style="margin-bottom: 300px!important">
            <v-col v-if="plans.length!==4"></v-col>
            <v-col
                lg="3"
                md="3"
                sm="12"
                style="margin-bottom:250px;"
                v-for="plan in plans" :key="plan.id">
              <plan-card :plan="plan"></plan-card>
            </v-col>
            <v-col v-if="plans.length!==4"></v-col>
          </v-row>
          <v-row v-else class="mt-16" style="margin-bottom: 300px!important">
            <v-col cols="12" class="text-center">
              <v-btn style="color: #ffbe66; font-size: 30px; font-family: Blinker-SemiBold,serif!important" text
                     to="/login-register">
                {{ planShowMessage }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>

      <!--        // FIRST TAB-->
      <!--        // SECOND TAB-->

      <!--          <v-tab-item>-->
      <!--            <v-row>-->
      <!--              <v-col-->
      <!--                  lg="4"-->
      <!--                  md="4"-->
      <!--                  sm="12"-->
      <!--                  cols="12"-->
      <!--                  v-for="userPlan in userPlans" :key="userPlan.id">-->
      <!--                <plan-card :plan="userPlan"></plan-card>-->
      <!--              </v-col>-->
      <!--              <v-col v-if="!isAuthenticated && (planDetails.parent ==='renew' || planDetails.parent ==='upgrade')"-->
      <!--                     cols="12" class="text-center">-->
      <!--                <v-btn style="color: #ffbe66; font-size: 30px; font-family: Blinker-SemiBold,serif!important" text-->
      <!--                       to="/login-register">-->
      <!--                  Please login to your account to see your plans!-->
      <!--                </v-btn>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-tab-item>-->
      <!--          <v-tab-item>-->
      <!--            <v-row>-->
      <!--              <v-col-->
      <!--                  lg="4"-->
      <!--                  md="4"-->
      <!--                  sm="12"-->
      <!--                  cols="12"-->
      <!--                  v-for="recommendedPlan in recommendedPlans" :key="recommendedPlan.id">-->
      <!--                <plan-card :plan="recommendedPlan"></plan-card>-->
      <!--              </v-col>-->
      <!--              <v-col v-if="!isAuthenticated" cols="12" class="text-center">-->
      <!--                <v-btn style="color: #ffbe66; font-size: 30px; font-family: Blinker-SemiBold!important" text-->
      <!--                       to="/login-register">-->
      <!--                  Please login to your account to see your plans!-->
      <!--                </v-btn>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-tab-item>-->
    </div>
    <div v-else style="background-color: #f4f4f8!important">
      <div class="text-left ml-6"
           style="color: #ffbe66;font-size: 30px;font-weight: 800;font-family: Blinker-ExtraBold!important">
        BEST AID
      </div>
      <div class="text-left ml-6"
           style="color: #ffbe66;font-size: 25px;font-family: Blinker-SemiBold!important;margin-top:-20px!important">
        REVIEWs
      </div>
      <div class="mx-6 mt-8" style="color:#a8a8a8;text-align: justify;font-size: 15px">
        Animated Comprehensive USMLE Step 1 Review <br>
        Integrated Book, Q-bank, Animations &amp; Videos <br>
        Empowered by Scanning Technology Live Review <br>
        Sessions
      </div>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn height="40" elevation="0" class="white--text text-capitalize" color="#39bf88"
                 style="font-family: Blinker-Bold!important;border-radius: 15px" width="70%"
                 to="/plans"
          >
            Buy
          </v-btn>
        </v-col>
        <v-col class="text-left">
          <v-btn height="40" elevation="0" class="white--text text-capitalize" color="#fdbd68"
                 style="font-family: Blinker-Bold!important;border-radius: 15px" width="70%"
                 to="/demo"
          >
            Demo
          </v-btn>
        </v-col>
        <v-col class="pl-10 text-left mt-4" cols="12"
               style="color: #39bf88!important;font-family: Blinker-SemiBold!important">
          “COMPLEX IS SIMPLIFIED!”
        </v-col>
        <v-col class="text-left pl-10" cols="12"
               style="color: #a8a8a8!important;font-family: Blinker-SemiBold!important">
          Step 1 Pricing <br>
          Questions about subscription? <span style="cursor: pointer;color: #39bf88!important"
                                              @click="$router.push('/plans')">Click here.</span>
        </v-col>
        <v-col cols="12" class="mt-1">
          <v-row style="background-color: #39bf88">
            <v-col style="font-family: Blinker-Bold!important"
                   :class="firstTab === 0 ? 'underlined-black-text' : 'white--text'" @click="firstTab = 0">
              First Time
            </v-col>
            <v-col style="font-family: Blinker-Bold!important"
                   :class="firstTab === 1 ? 'underlined-black-text' : 'white--text'" @click="firstTab = 1">
              Renewal
            </v-col>
            <v-col style="font-family: Blinker-Bold!important"
                   :class="firstTab === 2 ? 'underlined-black-text' : 'white--text'" @click="firstTab = 2">
              Upgrade
            </v-col>
          </v-row>
          <v-row class="mt-5" style="background-color: #39bf88">
            <v-col cols="2" @click="secondTab=0" :class="secondTab === 0 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar
            </v-col>
            <v-col cols="2" @click="secondTab=1" :class="secondTab === 1 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar Live
            </v-col>
            <v-col cols="4" @click="secondTab=2" :class="secondTab === 2 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar Live Online
            </v-col>
            <v-col cols="4" @click="secondTab=3" :class="secondTab === 3 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar Live In Person
            </v-col>
          </v-row>

          <v-row v-if="plans.length" class="mt-4"
                 style="margin-bottom: 100px!important">
            <v-col
                cols="6"
                class="pa-4"
                v-for="plan in plans" :key="plan.id">
              <div
                  style="border-radius: 12px;font-family: Blinker-Bold!important;
                  color: #3d3d3d!important;
                  border-style: solid;border-color: #39bf88!important;"
              >
                <div class="white--text" style="background-color: #39bf88!important;">
                  {{ plan.title }}
                </div>
                <div v-html="plan.description">

                </div>
                <div v-if="plan.expire">
                    <span style="font-family: Blinker-SemiBold,serif!important;color: #2d3136!important">
                      {{ plan.expire }} / days  &nbsp; {{ plan.price }} $
                    </span>
                </div>
                <div v-else>
                  <span style="font-family: Blinker-SemiBold,serif!important;color: #2d3136!important">∞</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-else class="mt-4" style="margin-bottom: 30px!important">
            <v-col cols="12" class="text-center">
              <v-btn style="color: #ffbe66; font-size: 15px; font-family: Blinker-SemiBold,serif!important" text
                     to="/login-register">
                {{ planShowMessage }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import PlanCard from "@/components/PlanCard";
import VideoPlayer from '@/components/VideoPlayer.vue';

export default {
  components: {PlanCard, VideoPlayer},
  data() {
    return {
      selectedPlan: 'firstTime',
      screenType: null,
      windowWidth: screen.width,
      isAuthenticated: false,
      firstTab: null,
      secondTab: 1,
      planDetails: {
        parent: 'first_time',
        child: 'bar_bundle'
      },
      planShowMessage: null,
      plans: [],
      userPlans: [],
      recommendedPlans: [],
      videoId: '',
      videoLoaded: false,
      videoOptions: {
        autoplay: false,
        fluid: true,
        controls: true,
        liveui: true,
        responsive: true,
        sources: [
          {
            src: '',
            type: "video/mp4"
          }
        ],
      }
    }
  },
  watch: {
    secondTab: function (value) {
      this.plans = []
      switch (value) {
        case 0:
          this.planDetails.child = 'bar'
          break;
        case 1:
          this.planDetails.child = 'bar_bundle'
          break;
        case 2:
          this.planDetails.child = 'bar_live_simple'
          break;
        case 3:
          this.planDetails.child = 'bar_live_in_person'
          break;
      }
      this.getPlans()
    },
    firstTab: function (value) {
      this.plans = []
      switch (value) {
        case 0:
          this.planDetails.parent = 'first_time'
          break;
        case 1:
          this.planDetails.parent = 'renew'
          break;
        case 2:
          this.planDetails.parent = 'upgrade'
          break;
      }
      this.getPlans()
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    getPlans() {
      window.axios.post(`api/plans`, {type: this.planDetails.child, use_type: this.planDetails.parent})
          .then((response) => {
            this.plans = response.data.data
          }).catch((err) => {
        if (err.response.status === 400) {
          this.planShowMessage = err.response.data.message
        }
      })
    },
    getData() {
      this.videoLoaded = false
      this.videoId = this.$route.params.id
      window.axios.get('api/videos/home-video/').then((res) => {
        this.videoPath = res.data.data.path
        this.videoOptions.sources[0]['src'] = this.baseURL + res.data.data.path
        this.videoLoaded = true
      }).catch((error) => {
        if (error.response.status === 401) {
          return
        }
      }).finally(() => {
        let self = this
        window.setTimeout(function () {
          self.firstTab = 0
        }, 1000)
      })
    },
  },
  mounted() {
    this.getScreenType()
    this.getData();
    this.windowWidth = window.screen.width
  }
}
</script>

<style scoped>

.underlined-black-text {
  color: black !important;
  text-decoration: underline;
}


.custom-margin-x {
  width: 1200px !important;
  display: table !important;
  margin: auto !important;
}

.Rectangle-6 {
  border-radius: 15px !important;
  background-color: #ffbe66 !important;
}

.page-title {
  font-family: Blinker-Bold, serif;
  font-size: 79.9px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #ffbe66;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.slash {
  height: 4px;
  width: 65px;
  background: #ffbe66;
  position: absolute;
  margin-top: 48px;
  margin-right: -12px !important;
  transform: rotate(-45deg)
}

.page-sub-title {
  margin-top: -15px !important;
  padding-top: 0 !important;
  font-family: Blinker-Regular, serif;
  font-size: 60.4px;
  font-weight: normal;
  line-height: 0.92;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffbe66;
}

.page-description {
  font-family: Blinker-SemiBold, serif;
  margin: 0;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a8a8a8;
}

.circle {
  height: 489px;
  width: 489px;
  background-color: #f4f5f8;
  border-radius: 50%;
  display: inline-block;
  z-index: 1;
  position: absolute;
  transform: translateX(-100px);
  left: 0;
}

.z-index-2 {
  z-index: 2;
}

.deactive {
  height: 300px !important;
}

.background-home {
  /*background-image: radial-gradient(#c4ccd1 20%,#E2E3EAFF 40%, white 40%);}*/
  background-image: linear-gradient(336deg, #f4f4f8 0%, #f4f4f8 49.9%, white 50%, white 100%);
}

.background-home-bottom {
  background-image: linear-gradient(to top, white 0%, white 25%, white 50%, #f4f4f8 100%);
}

.small-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #53a684;
  margin-top: -23px;
  margin-right: 16px;
}

.custom-slash {
  background: #a8a8a8;
  height: 2px;
  width: 35px;
  margin-top: -10px;
  margin-right: -20px;
  position: absolute;
  transform: rotate(-45deg)
}

.card-circle-and-slash-container {
  margin-top: 20px;
}

.page-tab-text {
  margin-top: 30px;
  width: 30%;
  height: 100%;
  font-size: 25px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: #dce1e5 !important;
}

@media only screen and (max-width: 560px) {
  .page-tab-text {
    font-size: 17px !important;
  }
}

.page-tab-active {
  height: 160px !important;
  background-color: white !important;
  font-size: 32px !important;
  padding: 5% !important;
  z-index: 1000 !important;
  transition: transform 0.5s ease;
  transform: translateY(-40px);
}

.page-tab-span {
  font-family: Blinker-SemiBold, serif;
  top: 15px !important;
  letter-spacing: normal !important;
  position: absolute;
  font-size: 30px !important;
}

.qr-code {
  z-index: 100;
  position: absolute;
  top: 34%;
  right: 31%;
  width: 6.2%;
  height: 2.8%;
  background-color: #e8ecef;
  border-radius: 1vw !important;
  padding: 0.6vw !important;
}
</style>