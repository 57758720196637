import { render, staticRenderFns } from "./MobileDefaultHeader.vue?vue&type=template&id=a3cde438&scoped=true&"
import script from "./MobileDefaultHeader.vue?vue&type=script&lang=js&"
export * from "./MobileDefaultHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3cde438",
  null
  
)

export default component.exports